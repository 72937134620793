import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import SearchBar from './SearchBar'
import { OCEAN_PADS_SVG, CLOSE_MENU_ICON } from '../../../constants/image.const'
import { USER_TYPE } from '../../../constants/app.const'
import { logout } from '../../../services/auth.service'
import { authActionReducer } from '../../../store/auth/authSlice'
import { modalsActionReducer } from '../../../store/modals/modalsSlice'
import { panelsActionReducer } from '../../../store/panels/panelsSlice'
import { searchActionReducer } from '../../../store/search/searchSlice'
import {
  CloseButtonContainer,
  LogoContainer,
  NavbarItem,
  HeaderPanelContainer,
  ContentContainer,
  HeaderPanelPanelContainer,
  HeaderContainer,
  StyledLink,
} from './HeaderPanel.style'

const HeaderPanel = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { user } = useSelector((state) => state.auth)
  const { headerPanelIsOpen } = useSelector((state) => state.panels)

  const resetUser = () => dispatch(authActionReducer?.setUser(null))
  const openAuthModal = () => dispatch(modalsActionReducer.setAuthModalIsOpen(true))
  const closeHeaderPanel = () => dispatch(panelsActionReducer?.setHeaderPanelIsOpen(false))
  const setListingType = (payload) => dispatch(searchActionReducer.setType(payload))

  const userIsLoggedIn = !!user
  const userIsRealtor = user?.user?.type === USER_TYPE.REALTOR
  const userIsAdmin = user?.user?.is_superuser

  const handleCloseOnClick = () => closeHeaderPanel()
  const handleRentOnClick = () => {
    setListingType('FOR_RENT')
    closeHeaderPanel()
  }
  const handleBuyOnClick = () => {
    setListingType('FOR_SALE')
    closeHeaderPanel()
  }
  const handleFavoritesOnClick = (event) => {
    if (!userIsLoggedIn) {
      event.preventDefault()
      event.stopPropagation()
      openAuthModal()
    }
    closeHeaderPanel()
  }
  const handleSignOutOnClick = () => {
    logout()
    resetUser()
    closeHeaderPanel()
    const path = location?.pathname
    if (path.includes('account') || path.includes('realtors')) navigate('/')
  }
  const handleSignInOnClick = () => {
    openAuthModal()
    closeHeaderPanel()
  }

  return (
    <HeaderPanelContainer className="hidden-desktop" isOpen={headerPanelIsOpen}>
      <HeaderPanelPanelContainer>
        <HeaderContainer>
          <CloseButtonContainer onClick={handleCloseOnClick}>
            <img src={CLOSE_MENU_ICON} alt="close-menu" />
          </CloseButtonContainer>
          <StyledLink to="/">
            <LogoContainer src={OCEAN_PADS_SVG} alt="oceanpads-logo" onClick={handleCloseOnClick} />
          </StyledLink>
        </HeaderContainer>
        <ContentContainer>
          <SearchBar />
          <StyledLink to="/account/sales" onClick={handleFavoritesOnClick}>
            <NavbarItem>Favorites</NavbarItem>
          </StyledLink>
          <StyledLink to="/search" onClick={handleRentOnClick}>
            <NavbarItem>Rent</NavbarItem>
          </StyledLink>
          <StyledLink to="/search" onClick={handleBuyOnClick}>
            <NavbarItem>Buy</NavbarItem>
          </StyledLink>
          <StyledLink to="/new-developments" onClick={handleCloseOnClick}>
            <NavbarItem>New Developments</NavbarItem>
          </StyledLink>
          {userIsLoggedIn ? (
            <>
              <StyledLink
                to={userIsAdmin ? '/admin' : userIsRealtor ? '/realtors' : '/account'}
                onClick={handleCloseOnClick}
              >
                <NavbarItem>{userIsAdmin ? 'Admin' : userIsRealtor ? 'My Realtor Page' : 'Account'}</NavbarItem>
              </StyledLink>
              <NavbarItem onClick={handleSignOutOnClick}>Sign Out</NavbarItem>
            </>
          ) : (
            <NavbarItem onClick={handleSignInOnClick}>Sign in/Register</NavbarItem>
          )}
        </ContentContainer>
      </HeaderPanelPanelContainer>
    </HeaderPanelContainer>
  )
}

export default HeaderPanel
